import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import TheLayout from './containers/TheLayout';

function App() {
  return (
    <HashRouter>
        <Switch>
          {/* <Redirect from="*" to="/setup" /> */}

          {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />*/}
          
          <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
        </Switch>
    </HashRouter>
  );
}

export default App;
