import React from 'react';

const Home = React.lazy(() => import('./pages/Home'));
const Details = React.lazy(() => import('./pages/Details'));
const Listing = React.lazy(() => import('./pages/ListingInfinite'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Finance = React.lazy(() => import('./pages/Finance'));
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
// const InventoryEdit = React.lazy(() => import('./views/dealership/inventory/InventoryEdit'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home, breadcrumb: 'Home' },
//   { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/cars', exact: true, name: 'Listing', component: Listing, breadcrumb: 'Cars' },  
  { path: '/cars/:id/details', exact: true, name: 'Details', component: Details, breadcrumb: 'Details' },
  { path: '/contact', exact: true, name: 'Contact', component: Contact, breadcrumb: 'Contact' },  
  { path: '/finance', exact: true, name: 'Finance', component: Finance, breadcrumb: 'Finance' },  
];

export default routes;
