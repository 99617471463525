import React, { Suspense } from 'react';
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { AnimateOnChange } from 'react-animation';
import { animations, easings } from 'react-animation'
import 'react-animation/dist/keyframes.css'

import ErrorSection from '../pages/ErrorSection';

// routes config
import routes from '../routes';
  
const showLoadingScreen = (loaded = false) => (
    <div id="page-preloader" style={loaded ? {animation: `fade-out ${easings.linear} 500ms forwards`} : undefined}><span className="spinner"></span></div>
);

const getErrorSection = (props) => <ErrorSection {...props} />;

// class TheLayout extends React.Component {
//     render() {
//         return (
//             <>
//                 <header className="b-topBar wow slideInDown" data-wow-delay="0.7s">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-5 col-xs-6">
//                                 <div className="b-topBar__addr" style={{ paddingRight: 20 }}>
//                                     <span className="fa fa-map-marker"></span>
//                                     5020 E WASHINGTON BLVD, COMMERCE, CA 90040
//                                 </div>
//                             </div>
//                             <div className="col-md-2 col-xs-6">
//                                 <div className="b-topBar__tel">
//                                     <span className="fa fa-phone"></span>
//                                     1-818-900-1913
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </header>
//                 <nav className="b-nav">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-sm-3 col-xs-4">
//                                 <div className="b-nav__logo wow slideInLeft" data-wow-delay="0.3s">
//                                     <h3><a href="/">EVO<span>CARS</span></a></h3>
//                                     <h2><a href="/">EVOLUTION CARS</a></h2>
//                                 </div>
//                             </div>
//                             <div className="col-sm-9 col-xs-8">
//                                 <div className="b-nav__list wow slideInRight" data-wow-delay="0.3s">
//                                     <div className="navbar-header">
//                                         <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#nav">
//                                             <span className="sr-only">Toggle navigation</span>
//                                             <span className="icon-bar"></span>
//                                             <span className="icon-bar"></span>
//                                             <span className="icon-bar"></span>
//                                         </button>
//                                     </div>
//                                     <div className="collapse navbar-collapse navbar-main-slide" id="nav">
//                                         <ul className="navbar-nav-menu">
//                                             <li><a href="#">Home</a></li>
//                                             <li><a href="#">Cars</a></li>
//                                             <li><a href="#">Finance</a></li>
//                                             <li><a href="article.html">services</a></li>
//                                             <li><a href="contacts.html">Contact</a></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </nav>

//                 <Suspense fallback={loading}>
//                     <Switch>
//                         {routes.map((route, idx) => {
//                             return route.component && (
//                                 <Route
//                                     key={idx}
//                                     path={route.path}
//                                     exact={route.exact}
//                                     name={route.name}
//                                     render={props => (
//                                         <route.component {...props} />
//                                     )} />
//                             )
//                         })}
//                         <Redirect path="*" to="/" />
//                     </Switch>
//                 </Suspense>

//                 <div className="b-features">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-9 col-md-offset-3 col-xs-6 col-xs-offset-6">
//                                 <ul className="b-features__items">
//                                     <li className="wow zoomInUp" data-wow-delay="0.3s" data-wow-offset="100">Low Prices, No Haggling</li>
//                                     <li className="wow zoomInUp" data-wow-delay="0.3s" data-wow-offset="100">Largest Car Dealership</li>
//                                     <li className="wow zoomInUp" data-wow-delay="0.3s" data-wow-offset="100">Multipoint Safety Check</li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <footer className="b-footer">
//                     <a id="to-top" href="#this-is-top"><i className="fa fa-chevron-up"></i></a>
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-xs-4">
//                                 <div className="b-footer__company wow fadeInLeft" data-wow-delay="0.3s">
//                                     <div className="b-nav__logo">
//                                         <h3><a href="home.html">EVO<span>CARS</span></a></h3>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-3 col-xs-6" style={{ color: "white" }}>
//                                 <span className="fa fa-phone"></span>
//                                 1-818-900-1913
//                                 <br />
//                                 <span className="fa fa-map-marker"></span>
//                                 5020 E WASHINGTON BLVD, COMMERCE, CA 90040
//                             </div>
//                             <div className="col-xs-8">
//                                 <div className="b-footer__content wow fadeInRight" data-wow-delay="0.3s">
//                                     <div className="b-footer__content-social">
//                                         <a href="#"><span className="fa fa-facebook-square"></span></a>
//                                         <a href="#"><span className="fa fa-twitter-square"></span></a>
//                                         <a href="#"><span className="fa fa-google-plus-square"></span></a>
//                                         <a href="#"><span className="fa fa-instagram"></span></a>
//                                         <a href="#"><span className="fa fa-youtube-square"></span></a>
//                                         <a href="#"><span className="fa fa-skype"></span></a>
//                                     </div>
//                                     <nav className="b-footer__content-nav">
//                                         <ul>
//                                             <li><a href="home.html">Home</a></li>
//                                             <li><a href="404.html">Pages</a></li>
//                                             <li><a href="listings.html">Inventory</a></li>
//                                             <li><a href="about.html">About</a></li>
//                                             <li><a href="404.html">Services</a></li>
//                                             <li><a href="blog.html">Blog</a></li>
//                                             <li><a href="listTable.html">Shop</a></li>
//                                             <li><a href="contacts.html">Contact</a></li>
//                                         </ul>
//                                     </nav>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </footer>

//                 <script src="js/jquery-1.11.3.min.js"></script>
//                 <script src="js/jquery-ui.min.js"></script>
//                 <script src="js/bootstrap.min.js"></script>
//                 <script src="js/modernizr.custom.js"></script>

//                 <script src="assets/rendro-easy-pie-chart/dist/jquery.easypiechart.min.js"></script>
//                 <script src="js/waypoints.min.js"></script>
//                 <script src="js/jquery.easypiechart.min.js"></script>
//                 <script src="js/classie.js"></script>

//                 {/* Switcher */}
//                 <script src="assets/switcher/js/switcher.js"></script>
//                 {/* Owl Carousel */}
//                 <script src="assets/owl-carousel/owl.carousel.min.js"></script>
//                 {/* bxSlider */}
//                 <script src="assets/bxslider/jquery.bxslider.js"></script>
//                 {/* jQuery UI Slider */}
//                 <script src="assets/slider/jquery.ui-slider.js"></script>

//                 {/* Theme */}
//                 <script src="js/jquery.smooth-scroll.js"></script>
//                 <script src="js/wow.min.js"></script>
//                 <script src="js/jquery.placeholder.min.js"></script>
//                 <script src="js/theme.js"></script>
//             </>
//         )
//     }
// }

class TheLayout extends React.Component {
    render() {
        return (
            <Suspense fallback={showLoadingScreen()}>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} showLoadingScreen={showLoadingScreen} errorSection={getErrorSection(props)} />
                                )} />
                        )
                    })}
                    <Redirect path="*" to="/" />
                </Switch>
            </Suspense>
        )
    }
}

export default React.memo(TheLayout);