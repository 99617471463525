import React from 'react';
import { Link } from 'react-router-dom';

class ErrorSection extends React.Component {
    render() {
        return (
            <>
                <section className="b-error s-shadow">
                    <div className="container">
                        <h1 className="wow zoomInUp" data-wow-delay="0.7s">Error</h1>
                        <img className="img-responsive center-block wow zoomInUp" data-wow-delay="0.7s" src="images/backgrounds/404.png" alt="404" />
                        <h2 className="s-lineDownCenter wow zoomInUp" data-wow-delay="0.7s">We couldn't load this page</h2>
                        <p className="wow zoomInUp" data-wow-delay="0.7s">We're sorry you couldn't get where you wanted. Please try updating the page, maybe we already fixed it. Otherwise we're most likely already working on this issue and will bring the page back shortly.  Please feel free to give us a call.</p>
                        <Link to='/'><h3 className="s-title wow zoomInUp" data-wow-delay="0.7s">RETURN TO HOME PAGE</h3></Link>
                    </div>
                </section>
            </>
        );
    }
}

export default ErrorSection;